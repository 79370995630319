import { useState, useEffect, useMemo, useCallback } from 'react';

import { kmlMapping } from 'hooks/useKmlVisibility/kmlMapping';

const useKmlVisibility = (
    map: google.maps.Map | undefined,
    isLoaded: boolean,
    showCoalClosure?: boolean,
    showMSA?: boolean,
    showCEJST?: boolean,
    showPPC?: boolean,
    showCategory1?: boolean,
    showMediumScaleSolar?: boolean,
    showLargeScaleSolar?: boolean,
) => {
    const [allKmlUrls, setAllKmlUrls] = useState<string[]>([]);
    const [visibleKmlUrls, setVisibleKmlUrls] = useState<string[]>([]);

    const stateBoundingBoxes: { [state: string]: google.maps.LatLngBounds } = useMemo(() => {
        if (!isLoaded) {
            return {
                California: {} as google.maps.LatLngBounds,
                Illinois: {} as google.maps.LatLngBounds,
                Massachusetts: {} as google.maps.LatLngBounds,
                Michigan: {} as google.maps.LatLngBounds,
                Mississippi: {} as google.maps.LatLngBounds,
                Ohio: {} as google.maps.LatLngBounds,
                Southbridge: {} as google.maps.LatLngBounds,
                Pennsylvania: {} as google.maps.LatLngBounds,
                'New York': {} as google.maps.LatLngBounds,
                Colorado: {} as google.maps.LatLngBounds,
                'New Jersey': {} as google.maps.LatLngBounds,
                Minnesota: {} as google.maps.LatLngBounds,
            };
        }

        return {
            California: new google.maps.LatLngBounds(
                new google.maps.LatLng(32.5343, -124.4096), // Southwest
                new google.maps.LatLng(42.0095, -114.1312), // Northeast
            ),
            Illinois: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.970298, -91.513079), // Southwest
                new google.maps.LatLng(42.508337, -87.495202), // Northeast
            ),
            Southbridge: new google.maps.LatLngBounds(
                new google.maps.LatLng(42.0481, -72.0861), // Southwest
                new google.maps.LatLng(42.1076, -71.9711), // Northeast
            ),
            Massachusetts: new google.maps.LatLngBounds(
                new google.maps.LatLng(41.237964, -73.508142), // Southwest
                new google.maps.LatLng(42.886589, -69.928393), // Northeast
            ),
            Michigan: new google.maps.LatLngBounds(
                new google.maps.LatLng(41.696118, -90.418136), // Southwest
                new google.maps.LatLng(48.306064, -82.413474), // Northeast
            ),
            Mississippi: new google.maps.LatLngBounds(
                new google.maps.LatLng(30.1739, -91.655), // Southwest
                new google.maps.LatLng(35.008, -88.0979), // Northeast
            ),
            Ohio: new google.maps.LatLngBounds(
                new google.maps.LatLng(38.4032, -84.8203), // Southwest
                new google.maps.LatLng(41.9772, -80.5186), // Northeast
            ),
            Pennsylvania: new google.maps.LatLngBounds(
                new google.maps.LatLng(39.7205, -80.5196), // Southwest
                new google.maps.LatLng(42.2696, -74.698), // Northeast
            ),
            'New York': new google.maps.LatLngBounds(
                new google.maps.LatLng(40.477399, -79.76259), // Southwest
                new google.maps.LatLng(45.01585, -71.185086), // Northeast
            ),
            Colorado: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.970298, -109.0451), // Southwest
                new google.maps.LatLng(41.0053, -102.0584), // Northeast
            ),
            'New Jersey': new google.maps.LatLngBounds(
                new google.maps.LatLng(38.9166, -75.5666), // Southwest
                new google.maps.LatLng(40.4833, -73.7002), // Northeast
            ),
            Minnesota: new google.maps.LatLngBounds(
                new google.maps.LatLng(43.4795, -96.6115), // Southwest
                new google.maps.LatLng(49.3844, -89.099), // Northeast
            ),
        };
    }, [isLoaded]);

    const getIntersectingStates = useCallback(
        (url: string, mapBounds?: google.maps.LatLngBounds): string[] => {
            if (!mapBounds) return [];

            return Object.keys(stateBoundingBoxes).filter((state) => {
                const stateBounds = stateBoundingBoxes[state];
                const intersects = mapBounds.intersects(stateBounds);

                return (
                    intersects &&
                    (Object.values(kmlMapping.CoalClosure[state] ?? []).includes(url) ||
                        Object.values(kmlMapping.MSA_NMSA[state] ?? []).includes(url) ||
                        Object.values(kmlMapping.lowIncomeCEJST[state] ?? []).includes(url) ||
                        Object.values(kmlMapping.lowIncomeCategory1[state] ?? []).includes(url) ||
                        Object.values(kmlMapping.lowIncomePPC[state] ?? []).includes(url) ||
                        Object.values(kmlMapping.solarMedium[state] ?? []).includes(url) ||
                        Object.values(kmlMapping.solarLarge[state] ?? []).includes(url))
                );
            });
        },
        [stateBoundingBoxes],
    );

    const handleBoundsChanged = useCallback(() => {
        if (!map) return;

        const bounds = map.getBounds();

        if (bounds) {
            const newVisibleKmlUrls = allKmlUrls.filter((url) => {
                const intersectingStates = getIntersectingStates(url, bounds);

                return intersectingStates.length > 0;
            });

            // Update state only if there is a change
            if (JSON.stringify(newVisibleKmlUrls) !== JSON.stringify(visibleKmlUrls)) {
                setVisibleKmlUrls(newVisibleKmlUrls);
            }
        }
    }, [map, allKmlUrls, getIntersectingStates, visibleKmlUrls]);

    useEffect(() => {
        const urls: string[] = [];

        if (showCoalClosure) urls.push(...Object.values(kmlMapping.CoalClosure).flat());

        if (showMSA) urls.push(...Object.values(kmlMapping.MSA_NMSA).flat());

        if (showCEJST) urls.push(...Object.values(kmlMapping.lowIncomeCEJST).flat());

        if (showPPC) urls.push(...Object.values(kmlMapping.lowIncomePPC).flat());

        if (showCategory1) urls.push(...Object.values(kmlMapping.lowIncomeCategory1).flat());

        if (showMediumScaleSolar) urls.push(...Object.values(kmlMapping.solarMedium).flat());

        if (showLargeScaleSolar) urls.push(...Object.values(kmlMapping.solarLarge).flat());

        setAllKmlUrls(urls);
    }, [showCoalClosure, showMSA, showCEJST, showPPC, showCategory1, showMediumScaleSolar, showLargeScaleSolar]);

    useEffect(() => {
        handleBoundsChanged();
    }, [allKmlUrls]);

    return { visibleKmlUrls, handleBoundsChanged };
};

export default useKmlVisibility;
