import { KML_BUCKET_BASE_URL } from 'utils/constants';

export type KmlMappingType = {
    CoalClosure: {
        [state: string]: string[];
    };
    MSA_NMSA: {
        [state: string]: string[];
    };
    lowIncomeCEJST: {
        [state: string]: string[];
    };
    lowIncomePPC: {
        [state: string]: string[];
    };
    solarMedium: {
        [state: string]: string[];
    };
    solarLarge: {
        [state: string]: string[];
    };
    lowIncomeCategory1: {
        [state: string]: string[];
    };
};

export const kmlMapping: KmlMappingType = {
    CoalClosure: {
        Illinois: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Illinois.kmz`)],
        Massachusetts: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Massachusetts.kmz`)],
        Michigan: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Michigan.kmz`)],
        Ohio: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Ohio.kmz`)],
        California: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/California.kmz`)],
        'New York': [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/New York.kmz`)],
        Pennsylvania: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Pennsylvania.kmz`)],
        Colorado: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Colorado.kmz`)],
        'New Jersey': [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/New Jersey.kmz`)],
        Minnesota: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Minnesota.kmz`)],
    },
    MSA_NMSA: {
        Illinois: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Illinois.kmz`)],
        Massachusetts: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Massachusetts.kmz`)],
        Michigan: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Michigan.kmz`)],
        Ohio: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Ohio.kmz`)],
        California: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/California.kmz`)],
        'New York': [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/New York.kmz`)],
        Pennsylvania: [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Pennsylvania.kmz`)],
        'New Jersey': [encodeURI(`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/New Jersey.kmz`)],
    },
    lowIncomeCEJST: {
        Illinois: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Illinois.kmz`)],
        Massachusetts: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Massachusetts.kmz`)],
        Michigan: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Michigan.kmz`)],
        Ohio: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Ohio.kmz`)],
        California: Array.from({ length: 4 }, (_, i) => i + 1).map((i) =>
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/California/California-${i}.kmz`),
        ),
        'New York': [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/New York.kmz`)],
        Pennsylvania: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/MSA_NMSA/Pennsylvania.kmz`)],
        Colorado: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Colorado.kmz`)],
        'New Jersey': [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/New Jersey.kmz`)],
        Minnesota: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Minnesota.kmz`)],
    },
    lowIncomePPC: {
        Illinois: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/IL.kmz`)],
        Massachusetts: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/MS.kmz`)],
        Michigan: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/MI.kmz`)],
        Ohio: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/OH.kmz`)],
        'New York': [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/NY.kmz`)],
        Pennsylvania: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/PA.kmz`)],
        Colorado: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/CO.kmz`)],
    },
    lowIncomeCategory1: {
        Illinois: [
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Illinois-1.kmz`),
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Illinois-2.kmz`),
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Illinois-3.kmz`),
        ],
        Massachusetts: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Massachusetts.kmz`)],
        Michigan: [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Michigan.kmz`)],
        Ohio: [
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Ohio-1.kmz`),
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Ohio-2.kmz`),
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Ohio-3.kmz`),
        ],
        California: Array.from({ length: 13 }, (_, i) => i + 1).map((i) =>
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/California/California-${i}.kmz`),
        ),
        'New York': Array.from({ length: 4 }, (_, i) => i + 1).map((i) =>
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/New York/New York-${i}.kmz`),
        ),
        Pennsylvania: Array.from({ length: 4 }, (_, i) => i + 1).map((i) =>
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Pennsylvania/Pennsylvania-${i}.kmz`),
        ),
        Colorado: Array.from({ length: 3 }, (_, i) => i + 1).map((i) =>
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Colorado/Colorado-${i}.kmz`),
        ),
        'New Jersey': [encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/New Jersey.kmz`)],
        Minnesota: Array.from({ length: 3 }, (_, i) => i + 1).map((i) =>
            encodeURI(`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Minnesota/Minnesota-${i}.kmz`),
        ),
    },
    solarMedium: {
        Southbridge: [encodeURI(`${KML_BUCKET_BASE_URL}/zoning-map/mediumScale/Southbridge.kmz`)],
    },
    solarLarge: {
        Southbridge: [encodeURI(`${KML_BUCKET_BASE_URL}/zoning-map/largeScale/Southbridge.kmz`)],
    },
};
